import socketio from 'socket.io-client';

import Env from '../config/Environment';

const socketConnection = socketio(Env.SOCKET_URL, {
  transports: ['websocket'],
  autoConnect: false,
});

export default socketConnection;
